@import "@styles/mixins/checkbox";
@import "@styles/mixins/fill-svg";
@import "@styles/transitions";
@import "@styles/colors";
@import "@styles/mixins/button";

$toggle-icon-color-non-active: #b4b4b4;
$bulk-action-icon-active: #6c72a5;
$color-dark-white: #e9e9e9;
$color-light-blue: #a4aacf;
$color-normal-grey: #b4b4b4;

.container {
	flex: 1; // this container should grow vertically till the bottom of page
	border: 1px solid $color-dark-white;
	border-right: none;
	border-bottom: none;
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.03);
	overflow-y: hidden;
}

.filterOperations {
	min-height: 62px;
	padding: 0 14px;
	display: flex;
	background: #fbfbfb;
	border-bottom: 1px solid #eeeeee;

	input[type="checkbox"].checkbox {
		@include styled-checkbox(
			$size: 14px,
			$tick-size: 11px,
			$border-radius: 2px,
			$border-size: 1px,
			$checked-bg: $color-darken,
			$unchecked-border-color: $color-alto,
			$checked-border-color: $color-darken
		);

		cursor: pointer;
		position: relative;
		background: $color-white;
		align-self: center;
		margin: 0.5rem;
		transition: background-color $transition-dur-hover linear, opacity $transition-dur-inventory-view linear;

		&[data-checked="indeterminate"] {
			border: 2px solid $color-darken;
			padding: 6px;

			&:after {
				content: "";
				display: block;
				width: 8px;
				height: 2px;
				background: $color-darken;

				position: absolute;
				left: 2px;
				top: 5px;
			}
		}

		&:disabled {
			opacity: 0.5;
		}
	}

	.itemsCount {
		color: $color-darken;
		font-size: 13px;
		margin-left: 12px;
		align-self: center;
		line-height: 14px;
		vertical-align: middle;
	}

	.filterButton {
		@include button-bright;

		align-self: center;
		margin-left: 12px;
	}

	.sortButton {
		align-self: center;
		margin-left: 18px;
	}

	.importArtworksButton {
		@include button-bright;
		@include button-with-icon;

		align-self: center;
		margin-left: auto;
	}

	.addWorkButton {
		@include button-dark;

		align-self: center;
		margin-left: 8px;
	}

	.viewToggleWrapper {
		display: flex;
		align-items: center;
		margin-left: 4px;
		margin-right: 16px;

		.viewToggleButton {
			background: none;
			border: none;
			height: 20px;
			width: 20px;
			padding: 0;

			svg {
				display: block;
			}

			path {
				fill: $toggle-icon-color-non-active;
				transition: fill $transition-dur-inventory-view linear;
			}

			&.isActive path {
				fill: $color-darken;
			}
		}
	}
}

.viewWrapper {
	flex: 1;
	overflow-y: hidden;
}

.viewContainer {
	display: none;
	opacity: 0;
	will-change: transform, opacity;
	width: 100%;
	height: 100%;
}

.emptyResultsWrapper {
	position: relative;
	width: 100%;
	height: 100%;

	.emptyResults {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-width: 311px;
		text-align: center;

		.title {
			font-weight: bold;
			font-size: 18px;
			text-align: center;
			color: #282828;
		}

		.description {
			font-size: 15px;
			text-align: center;
			color: #898989;
		}

		.addWorkButton {
			@include button-dark;

			margin-top: 24px;
			display: inline-block;
		}
	}
}
