@import "@styles/mixins/fill-svg";

.droppable {
	transition: background-color 100ms linear;

	.uploadButton {
		border: none;
		background: none;
		width: 160px;
		height: 160px;

		.iconWrapper {
			@include svg-path-colored(#dfdfdf);
			@include svg-path-transition(100ms, linear);
		}

		.captionWrapper {
			font-size: 14px;
			line-height: 17px;
			color: #767676;
			margin-top: 8px;
		}

		&:hover .iconWrapper {
			@include svg-path-colored(#767676);
		}
	}

	.draggable {
		height: 160px;
		width: 160px;
		position: relative;
		overflow: hidden;
		cursor: move;

		.image {
			width: 100%;
			height: 100%;

			img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}

		.removeIcon {
			@include svg-path-colored(#9d9d9d);
			@include svg-path-transition(100ms, linear);
			z-index: 2;

			border: none;
			background: #282828;
			border-radius: 4px;
			width: 32px;
			height: 32px;
			transition: background 100ms linear;

			position: absolute;
			right: 0;
			top: 0;
			display: none;

			&:hover {
				background: #eb4a27;
				@include svg-path-colored(#ffffff);
			}

			svg {
				display: block;
			}
		}

		.loadingSpinner {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			display: none;
		}

		&:hover .removeIcon {
			display: block;
		}

		&.isDragging .removeIcon {
			display: none;
		}

		&.isUploading {
			.loadingSpinner {
				display: block;
			}

			.image {
				opacity: 0.4;
			}
		}
	}

	&.readonly {
		pointer-events: none;
	}

	&.highlighted {
		background: rgba(238, 238, 238, 0.8);

		.uploadButton .iconWrapper {
			@include svg-path-colored(#767676);
		}
	}
}
