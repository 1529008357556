@import "@styles/icons";
@import "@styles/mixins/checkbox.scss";
@import "@styles/colors";

$color-light-green: #62c454;
$color-light-amber: #f4be4f;
$color-dark-white: #e9e9e9;
$color-normal-grey: #b4b4b4;

.tableRow {
	height: 56px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 0 14px;

	.collectionsCell {
		display: block;
	}

	&:hover {
		background: #f7f7f7;

		.checkbox {
			border: 1px solid $color-normal-grey;
		}

		.collectionsCell {
			display: block;
		}
	}

	&.inPreview {
		background-color: #f1f2f8;
		transition: background-color 100ms linear;

		.checkbox {
			border: 1px solid #a7a7a7;
		}
	}

	&.isSelected {
		background-color: #f1f2f8;
		transition: background-color 100ms linear;
	}
}

.thumbnailImage {
	width: 40px;
	height: 40px;

	img {
		object-fit: cover;
	}
}

.tableData {
	margin: 0;
	padding: 0.5rem;

	display: inline-block;
	box-sizing: border-box;
	font-size: 14px;

	&.checkboxCell {
		line-height: 8px;
	}

	&.thumbnailImageCell {
		max-width: 60px;
		padding-right: 4px;
	}

	&.statusCell {
		padding-left: 0;
		padding-right: 0;
	}

	&.stockCodeCell {
		color: #767676;
		white-space: nowrap;
		padding-left: 4px;
	}

	&.metaInfoCell {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		.itemDetail + .itemDetail {
			margin-left: 20px;
		}

		.itemAuthor {
			font-weight: bold;
		}

		.itemTitle {
			font-style: italic;
		}
	}

	&.collectionsCell {
		margin-left: auto;
		padding-right: 2px;
	}
}

abbr[title].itemStatusAbbr {
	display: block;
	padding: 5px;
	cursor: pointer;
	width: 27px;

	svg {
		display: block;
	}
}

.itemCollectionsList {
	.itemCollection {
		text-transform: uppercase;
		font-size: 10px;
		color: #767676;
		border: 1px solid $color-alto;
		border-radius: 2px;
		padding: 4px;
		font-weight: normal;
		white-space: nowrap;

		&.isArchivedCollection {
			background: $color-dark-white;
		}
	}

	.itemCollection + .itemCollection {
		margin-left: 4px;
	}
}
