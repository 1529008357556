/**
 * Default styles for List
 */

@import "@styles/mixins/lists";

.listWrapper {
	max-height: 300px;
	border-top: 1px solid #eeeeee;

	@include visible-scrollbar;
}

.ulContainer {
	padding: 0;
	margin: 0;

	li {
		list-style-type: none;
		outline: none;
	}
}

.listItem {
	display: flex;
	align-items: center;
	background: none;
	border: none;
	font-size: 14px;
	color: #282828;
	padding: 8px 12px;
	width: 100%;
	text-align: left;
}

li[data-active="true"] .listItem {
	background: #f7f7f7;
}
