@import "@styles/mixins/checkbox.scss";
@import "@styles/colors";

/**
 * Common Checkbox styles
 */

input[type="checkbox"].checkbox {
	@include styled-checkbox(
		$size: 14px,
		$tick-size: 11px,
		$border-radius: 2px,
		$border-size: 1px,
		$checked-bg: $color-darken,
		$unchecked-border-color: $color-alto,
		$checked-border-color: $color-darken
	);

	cursor: pointer;
	background: $color-white;
}