@mixin visible-scrollbar {
	overflow-y: auto;

	&::-webkit-scrollbar-thumb {
		border-radius: 8px;
		border: 2px solid white;
		background-color: rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar {
		-webkit-appearance: none;
	}

	&::-webkit-scrollbar:vertical {
		width: 11px;
	}
}
