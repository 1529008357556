@import "@styles/mixins/fill-svg.scss";
@import "@styles/transitions";
@import "@styles/colors";

$color-dark-white: #e9e9e9;

@mixin previewContainer {
	border: 1px solid $color-dark-white;
	border-bottom: none;
	border-right: none;
	overflow: hidden;
}

.outerContainer {
	@include previewContainer;
	transition: transform $transition-dur-action linear, filter $transition-dur-action linear;
	display: none;

	.innerContainer {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&.asBackground {
		filter: blur(3px);
		pointer-events: none;
	}

	&.isVisible {
		display: block;
		width: 400px;
	}
}

.floatingContainer {
	@include previewContainer;
	transition: transform $transition-dur-action linear, opacity $transition-dur-action linear;

	position: absolute;
	height: 100%;
	width: 400px;
	right: 0;
	background-color: $color-white;
	display: none;
}

.emptyStateWrapper {
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.emptyIcon {
		display: block;
		height: 70px;
		margin-bottom: 20px;

		svg {
			height: 100%;
			width: 100%;

			path {
				fill: #999393
			}
		}
	}

	.emptyLabel {
		font-size: 18px;
		color: #999393;
		text-align: center;
	}
}
