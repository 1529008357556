.emptyImage {
	background: #f7f7f7;
	display: flex;
	justify-content: center;
	width: 100%;
	height: 100%;

	svg {
		width: 20px;
		display: block;
		align-self: center;
	}
}