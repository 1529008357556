$speed: 200ms;

.statusButton {
	background: none;
	border: none;
	padding-left: 0;
	display: flex;
	align-items: center;

	span {
		margin-left: 7px;
	}
}

.listWrapper {
	width: 280px;
	background-color: #ffffff;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
	border-radius: 8px;

	&.isShown {
		transition: height $speed ease;
	}

	.listItem {
		display: flex;
		align-items: center;
		width: 100%;
		background: none;
		font-size: 14px;
		color: #282828;
		border: none;
		padding: 6px 6px;
		text-align: left;

		span + svg {
			margin-left: 5px;
		}

		svg + span {
			margin-left: 5px;
		}

		&.hasNestedList {
			height: 40px;
		}

		&.header {
			height: 40px;
			border-bottom: 1px solid #eeeeee;
			font-weight: bold;
		}
	}

	li[data-active="true"] .listItem {
		background: #f7f7f7;
	}
}
