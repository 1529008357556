@import "@styles/transitions";

$transition-dur: $transition-dur-action;

.enter {
	display: block;
	opacity: 0;
	transform: translate(100%, 0);
}

.enterActive, .enterDone {
	display: block;
	opacity: 1;
	transform: translate(0, 0);

	transition: opacity $transition-dur linear, transform $transition-dur linear;
}

.exit {
	display: block;
	opacity: 1;
	transform: translate(0, 0);
}

.exitActive {
	display: block;
	opacity: 0;
	transform: translate(100%, 0);

	transition: opacity $transition-dur linear, transform $transition-dur linear;
}

.exitDone {
	display: none;
}
