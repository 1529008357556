@import "@styles/mixins/common";
@import "@styles/mixins/button";

.dialogWrapper {
	.inputWrapper + .inputWrapper {
		margin-top: 16px;
	}

	.actions {
		display: flex;
		border-top: 1px solid #eeeeee;

		.deleteBtn {
			@include button-basic($padding: 6px 0);
			@include colored($color: #eb4a27);
		}

		.closeBtn {
			@include button-bright($fontWeight: bold);
			margin-left: auto;
		}

		.saveBtn {
			@include button-dark($fontWeight: bold);
			margin-left: 10px;
		}
	}
}

.locationTab {
	.notesSection {
		margin-top: 32px;
	}
}
