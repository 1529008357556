.disclosureButton {
	font-weight: 600;
	font-size: 15px;
	line-height: 18px;
	color: #282828;
	background: none;
	border: none;
	padding: 12px 0;

	svg {
		margin-left: 6px;
	}
}

.magicPaste {
	margin-top: 12px;

	.description {
		font-size: 14px;
		color: #767676;
		margin-bottom: 8px;
	}

	.textarea {
		height: 139px;
		width: 100%;
		border: 1px solid #dfdfdf;
		box-sizing: border-box;
		border-radius: 4px 4px 0 0;
		display: block;
		outline: none;
		padding: 8px;
		resize: none;
		font-weight: normal;
		font-size: 14px;
		color: #282828;
		transition: border-color 100ms linear;

		&:hover {
			border: 1px solid #bfbfbf;
		}

		&:active, &:focus, &:focus-visible {
			border: 1px solid #616161;
		}
	}

	.submit {
		height: 33px;
		width: 100%;
		border: none;
		background: #282828;
		border-radius: 0 0 4px 4px;
		font-weight: bold;
		font-size: 14px;
		line-height: 17px;
		text-align: center;
		color: #ffffff;
		display: block;
		transition: background-color 100ms linear;
		cursor: pointer;

		&:hover {
			background: #000000;
		}
	}
}