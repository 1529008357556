@import "@styles/breakpoints.scss";
@import "@styles/colors";

.inventorySearchWrapper {
	.inventorySearch {
		max-width: 1400px;
		padding: 0 20px;
		margin: 0 auto 40px auto;
	}
}

.inventoryGrid { /* stylelint-disable-line */
	// element should take exactly all available height left
	flex: 1;
	display: flex;
	overflow: hidden;

	position: relative;

	.inventoryContainer {
		display: flex;
		flex-direction: column;
		background: $color-white;
	}

	.previewContainer { /* stylelint-disable-line */
		background: $color-white;
		display: none;
	}
}
