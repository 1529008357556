@import "@styles/colors";

.button {
	display: flex;
	align-items: center;

	.label {
		margin-right: 5px;
	}
}

.wrapper {
	background: $color-white;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	width: 193px;
	padding: 8px 16px;
	margin-top: 5px;

	.linkItem {
		font-weight: bold;
		font-size: 14px;
		color: $color-darken;
		display: block;
		margin: 4px 0;

		&:hover {
			text-decoration: none;
			opacity: 0.6;
		}
	}
}