@import "@styles/icons";
@import "@styles/mixins/checkbox";
@import "@styles/transitions";
@import "@styles/colors";

@mixin grid-cell {
	flex: 1;
	margin: 10px 40px;
}

$item-size: 208px;

.thumbnailImage {
	width: $item-size;
	height: $item-size;
	margin: 0 auto 16px auto;
	display: inline-block;
	border-radius: 2px;

	span {
		border-radius: 2px;
		line-height: unset;
	}

	img {
		border-radius: 2px;
		object-fit: contain;
	}
}

.checkboxWrapper { /* stylelint-disable-line */
	position: absolute;
	height: 40px;
	width: $item-size;

	input[type="checkbox"].checkbox {
		@include styled-checkbox(
			$size: 18px,
			$tick-size:	12px,
			$border-radius: 50%,
			$border-size: 2px,
			$checked-bg: $color-darken,
			$unchecked-border-color: $color-alto,
			$checked-border-color: $color-white
		);

		margin: 5px 0 0 5px;
		display: block;

		cursor: pointer;
		background: $color-white;
	}

	transition: opacity $transition-dur-hover linear;
	opacity: 0;
}

.gridCell {
	@include grid-cell;

	cursor: pointer;
	border: none;
	background: none;
	overflow: hidden;

	.itemThumbnailWrapper {
		position: relative;
		display: flex;
		justify-content: center;
	}

	.itemDetails {
		text-align: left;
		font-size: 13px;

		.itemDetail {
			display: block;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			.status {
				padding: 0;
				margin-left: -3.5px; // we need an icon to be in the same vertical baseline as text
				cursor: pointer;
			}

			&.itemAuthor {
				margin-top: 4px;
				font-weight: bold;
			}

			.itemTitle {
				font-style: italic;
			}
		}
	}

	&:hover, &.forceShowCheckbox {
		.checkboxWrapper {
			opacity: 1;
		}
	}
}
