.textareaWrapper {
	position: relative;

	.placeholder {
		position: absolute;
		top: 0;
		pointer-events: none;
		white-space: pre;
		color: #828282;

		width: 100%;
		height: 100%;

		padding: 8px 9px;
		font-size: 14px;
	}
}