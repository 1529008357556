.skeletonContainer {
	padding-top: 8px;

	.skeletonWrapper {
		height: 56px;
		display: flex;
		align-items: center;

		.skeleton {
			display: block;

			&.checkbox {
				height: 16px;
				width: 16px;

				margin-left: 22px;
			}

			&.thumbnail {
				height: 40px;
				width: 40px;

				margin-left: 16px;
			}

			&.property {
				height: 12px;

				margin-left: 12px;
			}
		}
	}
}