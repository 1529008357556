@import "@styles/mixins/fill-svg";
@import "@styles/transitions";
@import "@styles/colors";

$bulk-action-icon-active: #6c72a5;

.header {
	height: 46px;
	border-bottom: 1px solid #eeeeee;
	color: #767676;
	font-size: 14px;
	display: flex;
	align-items: center;
	padding: 10px 24px 10px 24px;

	.deselectButton {
		font-weight: bold;
		border: none;
		background: none;
		color: #6c72a5;
		transition: color $transition-dur-hover linear;

		&:hover {
			color: #acadd9;
		}
	}
}

.bulkOperationsWrapper {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.bulkOperationButton {
	@include svg-path-colored(#767676);

	background: none;
	border: none;
	display: flex;
	align-items: center;
	transition: opacity $transition-dur-action linear;
	width: 30px;

	span {
		margin-left: 8px;
		color: $color-black;
	}

	&:disabled {
		opacity: 0.2;
	}

	& + .bulkOperationButton {
		margin-left: 5px;
	}
}

.container {
	flex: 1;
	overflow-y: hidden;
}