.container {
	height: 100%;
	padding: 0 32px;
	transition: opacity 100ms linear, transform 100ms linear, top 100ms linear;
	will-change: transform;
	cursor: pointer;
	opacity: 0;
	transform: scale(0.7);

	&.animationEnter {
		opacity: 0;
		transform: scale(0.7);
	}

	&.animationEnterDone {
		opacity: 1;
		transform: scale(1);
	}

	&:hover {
		background-color: #f7f7f7;
	}

	.innerWrapper {
		display: flex;
		align-items: center;
		height: 100%;

		.description {
			flex: 1;
			font-size: 14px;
			margin-left: 16px;

			.author {
				font-weight: bold;
				line-height: 22px;
			}
		}
	}
}

.thumbnailWrapper {
	width: 70px;
	height: 70px;

	.thumbnail {
		width: 100%;
		height: 100%;

		img {
			object-fit: contain;
		}
	}
}
