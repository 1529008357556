@import "@styles/transitions";

$transition-dur: $transition-dur-inventory-view;

.enter, .appear {
	display: flex;
	opacity: 0;
	transform: translateY(30px);
}

.enterActive, .appearActive {
	display: flex;
	opacity: 1;
	transform: translateY(0);
	transition: opacity $transition-dur linear, transform $transition-dur linear;
}

.enterDone, .appearDone {
	display: flex;
	opacity: 1;
}

.exit {
	display: flex;
	opacity: 1;
	transform: translateY(0);
}

.exitActive {
	display: flex;
	opacity: 0;
	transform: translateY(30px);
	transition: opacity $transition-dur linear, transform $transition-dur linear;
}

.exitDone {
	display: none;
	opacity: 0;
}
