.imageWrapper {
	// forcing React-Skeleton to have same size as parent
	> span, .skeletonLoader, img {
		display: block;
		width: 100%;
		height: 100%;
	}

	.error {
		background: #6c757d;
	}
}