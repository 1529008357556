@import "@styles/mixins/fill-svg";
@import "@styles/mixins/button";

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100vh;
}

.header {
	display: flex;
	align-items: center;
	flex: 0 0 62px;
	border-bottom: 1px solid #eeeeee;
	padding: 0 24px;

	.closeButton {
		width: 30px;
		@include svg-path-colored(#767676);

		border: none;
		background: none;
	}

	.thumbnail {
		margin-left: 16px;
		width: 30px;
		height: 30px;

		img {
			object-fit: cover;
		}
	}

	.heading {
		margin-left: 16px;
		color: #767676;
		font-size: 15px;
		line-height: 18px;

		.title {
			font-style: italic;
		}
	}

	.saveButton {
		@include button-dark($fontSize: 14px, $fontWeight: bold);

		margin-left: auto;
		display: flex;
		align-items: center;

		svg {
			display: block;
		}

		&[disabled] .label {
			margin-left: 8px;
		}
	}
}

$content-min-width: 500px;
$content-max-width: 860px;
$content-padding: 24px;

.contentWrapper {
	overflow: scroll;

	.content {
		min-width: $content-min-width + $content-padding;
		max-width: $content-max-width + $content-padding;
		margin: 100px auto 0 auto;
		padding: 0 $content-padding
	}
}

.tabs {
	border-bottom: 1px solid #e9e9e9;
	height: 40px;
	list-style-type: none;
	padding-inline-start: 0;

	.tab {
		background: none;
		border: none;
		color: #898989;
		font-weight: 600;
		font-size: 16px;
		height: calc(100% + 1px);
		padding: 0 0 6px 0;
		display: inline-block;
		cursor: pointer;

		&.selected {
			color: #282828;
			border-bottom: 2px solid #282828;
		}
	}

	.tab + .tab {
		margin-left: 32px;
	}
}
