.enter, .leave {
	transition: transform 100ms linear, opacity 100ms linear;
}

.enterFrom, .leaveTo {
	opacity: 0;
	transform: translate(0, 10px);
}

.enterTo, .leaveFrom {
	opacity: 1;
	transform: translate(0, 0);
}