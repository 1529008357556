@import "@styles/transitions.old";
@import "@styles/colors.old";
@import "@styles/fonts/lato-font";
@import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

html,
body {
	height: 100%;
}

.full-page {
	height: 100vh;
	width: 100vw;
}

html,
body,
#root {
	height: 100%;
}

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Lato", "Trebuchet MS", Helvetica Neue, sans-serif;
	// font-family: 'Public Sans', sans-serif;
}

.logo {
	width: 85px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/*
Login, Register, Verify forms
*/
.authForm {
	min-width: 350px;
	max-width: 550px;
	border-radius: 0px;
}

.stylish {
	// background: #f9f9f9 !important;
	border: 1px solid #e7e7e9 !important;
	box-sizing: border-box !important;
	border-radius: 6px !important;
}

input.form-input-auth {
	background: #f9f9f9 !important;
	border: 1px solid #e7e7e9 !important;
	box-sizing: border-box !important;
	border-radius: 6px !important;
	width: 100% !important;
	height: 40px !important;
}

textarea.form-input-auth {
	background: #f9f9f9 !important;
	border: 1px solid #e7e7e9 !important;
	box-sizing: border-box !important;
	border-radius: 6px !important;
}

/*
Navigation
*/
.nav-link {
	font-size: 0.9375rem;
	font-weight: 500;
	color: #000 !important;
}

.header--logo {
	font-size: 1.75rem;
	font-weight: 500;
}

/* Welcome page */
.app-icon {
	border-radius: 25px;
	width: 100px;
}

.mock-app {
	width: 250px;
}

/* Utilities */

.round-corners-7 {
	border-radius: 7px;
}

.full-width {
	width: 100% !important;
}

// Wraping new lines
.p_wrap {
	white-space: pre-line;
}

button {
	outline: none !important;
}
