.table {
	display: table;
	border-collapse: collapse;
	width: 100%;

	.row {
		display: table-row;
		border-bottom: 1px solid #eeeeee;
		width: 100%;
	}

	.cell {
		display: table-cell;
		padding: 12px 4px;

		.primary {
			display: block;
			font-size: 14px;
			line-height: 17px;
			color: #282828;
		}

		.secondary {
			display: block;
			font-size: 13px;
			line-height: 16px;
			color: #767676;
		}

		.primary + .secondary {
			margin-top: 4px;
		}
	}

	.row.header {
		text-transform: uppercase;

		.cell {
			font-weight: 600;
			font-size: 11px;
			line-height: 13px;
			color: #767676;
		}
	}

	.row.entity {
		height: 65px;
		cursor: pointer;

		&:hover {
			background: #f7f7f7;
		}
	}
}
