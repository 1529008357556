@import "@styles/mixins/button";

.actionButton {
	@include button-dark($fontWeight: bold);

	padding: 6px 0;
	width: 110px;
	margin-top: 25px;
}

.page {
	margin: 0 150px;
	padding: 85px 15px 0;
	max-width: 720px;

	.header {
		font-size: 44px;
		font-weight: 600;
	}

	.description {
		margin-top: 28px;
		font-size: 14px;
	}
}

.inputWrapper {
	width: 350px;

	&.url {
		margin-top: 27px;
	}

	&.ownershipDistance {
		margin-top: 30px;
	}

	&.source {
		margin-top: 10px;
	}
}

.checkboxWrapper {
	display: flex;
	align-items: center;
	padding: 10px 0 10px 2px;
	margin-top: 20px;

	.label {
		margin: 0 0 0 5px;
		font-size: 14px;
		cursor: pointer;
	}
}
