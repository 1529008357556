@import "@styles/mixins/lists";

$select-prefix: "selectInputField";

.selectWrapper {
	height: 35px;

	:global(.#{$select-prefix}__control) {
		min-height: 31px;
		border: 1px solid #dfdfdf;
		cursor: pointer;

		&:hover {
			border: 1px solid #bfbfbf;

			:global(.#{$select-prefix}__dropdown-indicator) {
				color: hsl(0, 0%, 60%);
			}
		}
	}

	:global(.#{$select-prefix}__indicator-separator) {
		display: none;
	}

	:global(.#{$select-prefix}__indicator) {
		padding: 4px 8px;
	}

	:global(.#{$select-prefix}__menu) {
		margin: 2px 0;
	}

	:global(.#{$select-prefix}__menu-list) {
		@include visible-scrollbar;
	}

	:global(.#{$select-prefix}__value-container) {
		font-size: 14px;
		color: #282828;
		padding: 2px 8px 2px 4px;
	}

	:global(.#{$select-prefix}__control--is-focused), :global(.#{$select-prefix}__control--is-focused):hover {
		border: 1px solid #616161;
		box-shadow: none;

		:global(.#{$select-prefix}__dropdown-indicator) {
			color: #616161;
		}
	}

	:global(.#{$select-prefix}__option) {
		font-size: 14px;
		color: #282828;
		cursor: pointer;

		&:global(.#{$select-prefix}__option--is-focused) {
			background-color: #f7f7f7;
		}

		&:global(.#{$select-prefix}__option--is-selected) {
			background-color: #c6c6c6;
		}
	}
}
