@import "@styles/mixins/checkbox.scss";
@import "@styles/colors";

.filterPopover {
	background: $color-white;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	width: 280px;
	margin-top: 5px;

	.header {
		margin-left: 4px;
		font-size: 14px;
		font-weight: bold;
		display: block;
	}

	section {
		padding: 10px 16px;
	}

	section + section {
		border-top: 1px solid #eeeeee;
	}

	.checkboxWrapper {
		display: inline-flex;
		align-items: center;
		font-size: 13px;
		margin-left: 4px;

		.label {
			margin: 0 0 0 5px;
			cursor: pointer;
		}
	}

	.checkboxWrapper + .checkboxWrapper {
		margin-left: 10px;
	}
}

.collectionsWrapper {
	margin-top: 16px;
	max-width: 100%;
	display: flex;
	flex-flow: row wrap;

	.collection {
		margin-left: 4px;
		margin-bottom: 4px;
		cursor: pointer;

		color: #767676;
		font-size: 12px;
		background: $color-white;
		border: 1px solid #e9e9e9;
		box-sizing: border-box;
		border-radius: 4px;
		padding: 4px 8px;

		&.collectionInFilter {
			color: $color-darken;
			background: #e9e9e9;
			border-radius: 4px;
		}
	}
}
