@import "@styles/colors";
@import "@styles/mixins/checkbox";

.popover {
	z-index: 999;

	.inputWrapper {
		border: 1px solid #e9e9e9;
		background: $color-white;
		margin-top: 5px;
		box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
		border-radius: 8px;
		overflow: hidden;
		width: 280px;
	}

	.suggestion {
		span {
			margin-left:	4px;
		}

		.checkbox {
			@include styled-checkbox(
				$size: 14px,
				$tick-size: 11px,
				$border-radius: 2px,
				$border-size: 1px,
				$checked-bg: $color-darken,
				$unchecked-border-color: $color-alto,
				$checked-border-color: $color-darken
			);

			margin-right: 8px;
		}

		&.newSuggestion {
			border-top: 1px solid #eeeeee;
			text-transform: none;
		}
	}

	.createCollectionButton {
		background: none;
		width: 100%;
		border: none;
		border-top: 1px solid #eeeeee;
		font-size: 14px;
		color: #282828;
		text-align: left;
	}
}