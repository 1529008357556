@import "@styles/mixins/common";
@import "@styles/mixins/button";

.dialogContainer {
	position: fixed;

	&.centered {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.dialog {
	background: #ffffff;
	box-shadow: 0 4px 60px rgba(0, 0, 0, 0.33);
	border-radius: 12px;
	padding: 32px;
	width: 450px;

	.header {
		font-weight: bold;
		font-size: 18px;
		line-height: 22px;
	}

	.description {
		font-size: 15px;
		color: #767676;
	}

	.actionsWrapper {
		display: flex;
		justify-content: flex-end;
		margin-top: 32px;

		.button {
			@include button-bright;
			padding: 5px 22px;

			&.danger {
				@include colored($color: #ffffff, $background: #d52f1c);
				@include border($borderColor: #d52f1c);
			}
		}

		.button + .button {
			margin-left: 12px;
		}
	}
}
