.record {
	height: 41px;
	display: flex;
	text-align: left;
	align-items: center;
	background: none;
	border: none;
	width: 100%;
	border-bottom: 1px solid #eeeeee;

	&:hover {
		background: #f7f7f7;
	}

	.main {
		flex: 1;
		font-size: 14px;
		color: #767676;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		display: flex;
		align-items: center;

		.icon {
			svg {
				display: block;
			}
		}

		.name {
			font-size: 14px;
			line-height: 17px;
			color: #282828;

			margin-left: 10px;
		}

		.notes {
			font-size: 14px;
			line-height: 17px;
			color: #767676;

			margin-left: 6px;
		}
	}

	.date {
		margin-left: 6px;
		text-align: right;

		font-size: 14px;
		line-height: 100%;
		color: #767676;
	}
}
