@import "@styles/mixins/button";
@import "@styles/mixins/checkbox.scss";
@import "@styles/colors";
@import "@styles/icons";

.iconButton {
	width: 20px;
	@include button-basic($padding: 0);
}

.sortPopover {
	background: $color-white;
	box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	width: 200px;
	padding: 4px 0;
	overflow: hidden;

	.header {
		padding: 8px 0 4px 8px;
		font-size: 12px;
		color: #767676;
		font-weight: 400;
		margin: 0;
	}

	.option {
		display: flex;
		margin: 0;
		padding: 0 8px;
		align-items: center;
		height: 32px;
		cursor: pointer;
		font-size: 14px;

		&:hover {
			background: #f7f7f7;
		}

		.label {
			margin: 0;
			cursor: pointer;
		}

		.radio {
			@include styled-checkbox(
				$size: 14px,
				$tick-size: 11px,
				$border-radius: 2px,
				$border-size: 1px,
				$checked-bg: rgba(0, 0, 0, 0),
				$unchecked-border-color: rgba(0, 0, 0, 0),
				$checked-border-color: rgba(0, 0, 0, 0),
				$checkmark-icon: "checkmark-black"
			);

			margin-left: auto;
		}
	}
}
