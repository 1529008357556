@import "./SelectInputField.module";

.wrapper {
	display: flex;

	.numericInput {
		width: auto;

		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	:global(.#{$select-prefix}__control) {
		height: 100%;
		width: 90px;

		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}
