/**
 * This function should be user for cases when we need to color some particular svg
 * Important: mixin targets `stroke` and `fill` attributes only if these attributes
 * are set in original svg path
 *
 * $color - actual color
 *
 * Examples:
 * svg-path-colored(#ffffff)
 */
@mixin svg-path-colored($color) {
	path {
		&[stroke] {
			stroke: $color;
		}

		&[fill] {
			fill: $color;
		}
	}
}

/**
 * Simply applies CSS transition to `stroke` and `fill` properties
 */
@mixin svg-path-transition($duration, $timingFunction) {
	path {
		&[stroke] {
			transition: stroke $duration $timingFunction;
		}

		&[fill] {
			transition: fill $duration $timingFunction;
		}
	}
}