@import "@styles/mixins/fill-svg";
@import "@styles/transitions";
@import "@styles/colors";

$color-manatee: #8d8fa9;
$color-whisper: #f1f2f8;
$color-whisper-darken: #dedfe7;
$color-red: #fad6d6;

.searchContainer {
	height: 46px;
	width: 100%;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	background-color: $color-white;
	transition: border $transition-dur-hover linear;
	border-bottom: 1px solid #eeeeee;

	.searchIcon {
		@include svg-path-colored(#bab9b9);
		margin-left: 23px;

		svg {
			display: block;

			path {
				transition: fill $transition-dur-hover linear;
			}
		}
	}

	input {
		background: none;
		border: none;
		outline: none;
		font-size: 15px;
		line-height: 18px;
		color: $color-black;
		flex: 1;
		margin-left: 10px;
	}

	&.isFocused {
		.searchIcon {
			@include svg-path-colored(#848383);
		}
	}
}
