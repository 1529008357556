.enter, .leave {
	transition: transform 100ms linear, opacity 100ms linear;
}

.enterFrom, .leaveTo {
	opacity: 0;
	transform: rotateX(25deg) scale(0.9);
}

.enterTo, .leaveFrom {
	opacity: 1;
	transform: rotateX(0deg) scale(1);
}