$border-default-color: #dfdfdf;
$border-hover-color: #bfbfbf;
$border-active-color: #616161;

@mixin input-styles {
	border: 1px solid #dfdfdf;
	box-sizing: border-box;
	border-radius: 4px;
	background: none;
	font-size: 14px;
	color: #282828;

	&:hover {
		border: 1px solid #bfbfbf;
	}

	&:active, &:focus, &:focus-visible {
		border: 1px solid #616161;
	}
}

.inputWrapper {
	.title {
		color: #767676;
		font-size: 14px;
		display: block;
		margin-bottom: 5px;
	}

	.input {
		@include input-styles;

		padding: 4px 6px;
		width: 100%;
		outline: none;
		transition: background-color 100ms linear;

		&.multiline {
			min-height: 114px;
			resize: vertical;
		}
	}

	.errorMessage {
		display: none;
		color: #ea1717;
		font-size: 14px;
	}

	&.narrow {
		width: 98px;
		display: inline-block;
	}

	&.narrow + &.narrow {
		margin-left: 8px;
	}

	&.isInvalid {
		.input {
			border: 1px solid #ea1717;
		}

		.errorMessage {
			display: block;
		}
	}

	&.isInline {
		display: inline-block;
		margin-left: 7px;
	}

	.toggleButton {
		@include input-styles;

		padding: 4px;
		width: 100%;
	}
}

$calendar-class: "react-datepicker";
.calendarPopper {
	font-family: "Lato", "Trebuchet MS", Helvetica Neue, sans-serif;

	:global(.#{$calendar-class}) {
		border: none;
		box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
	}

	:global(.#{$calendar-class}__triangle) {
		display: none;
	}

	:global(.#{$calendar-class}__navigation) {
		top: 9px;
	}

	:global(.#{$calendar-class}__header) {
		border-bottom: none;
	}
}

.checkboxWrapper {
	display: inline-flex;
	align-items: center;
	font-size: 13px;
	margin-left: 4px;
	padding: 10px 2px;

	.label {
		margin: 0 0 0 10px;
		cursor: pointer;
	}
}

