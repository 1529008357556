@import "@styles/mixins/fill-svg";
@import "@styles/transitions";
@import "@styles/colors";

$font-color: #222222;
$bulk-action-icon-active: #6c72a5;
$color-whisper-darken: #dedfe7;
$color-manatee: #8d8fa9;
$color-whisper: #f1f2f8;

@mixin collection {
	text-transform: uppercase;
	font-size: 12px;
	color: $color-manatee;
	background-color: $color-whisper;
	border-radius: 2px;
	padding: 4px;
	font-weight: 700;
	cursor: pointer;
	transition: background-color $transition-dur-hover linear;
}

.quickActionsWrapper {
	margin-left: auto;
	display: flex;
	align-items: center;

	.quickActionButton {
		@include svg-path-colored(#767676);

		background: none;
		border: none;
		align-items: center;
		transition: opacity $transition-dur-action linear;
		width: 30px;

		span {
			font-size: 16px;
			margin-left: 8px;
			color: $color-black;
		}

		&:disabled {
			opacity: 0.2;
		}

		& + .bulkOperationButton {
			margin-left: 5px;
		}
	}
}

.container {
	height: 100%;
	display: flex;
	flex-direction: column;

	.topActions {
		height: 46px;
		border-bottom: 1px solid #eeeeee;
		padding: 0 24px;
		display: flex;
		align-items: center;

		.closeIcon {
			@include svg-path-colored(#767676);

			svg {
				display: block;
			}

			background: none;
			border: none;
			padding: 0;
			outline: none;
			width: 20px;
			height: 20px;
		}
	}
}

.artworkInfo {
	padding: 24px;

	.description {
		margin-top: 32px;
		font-size: 14px;

		.heading {
			color: $font-color;

			.author {
				font-weight: bold;
				line-height: 22px;
			}

			.stockCode {
				line-height: 14px;
				color: #a8a8a8;
				margin-left: 20px;
			}
		}

		section {
			margin-top: 3px;
		}

		.retailPrice {
			margin-top: 20px;

			.title {
				line-height: 14px;
				color: #a8a8a8;
			}

			.content {
				margin-top: 4px;
			}
		}
	}

	.thumbnail {
		margin-left: 50px;

		.artworkImage {
			width: 250px;
			height: 250px;

			img {
				object-fit: contain;
			}
		}
	}
}

.editItemWrapper {
	text-align: right;
	margin-top: auto;
	padding: 10px 0;

	a:hover {
		text-decoration: none;
	}

	.editItemButton {
		background: none;
		border: none;
		font-weight: bold;
		color: #6c72a5;
		display: inline-flex;
		align-items: center;
		transition: color $transition-dur-hover linear;

		&:hover {
			color: #acadd9;
		}
	}
}

.collectionsWrapper {
	margin-top: 15px;

	.collection {
		@include collection;
		margin-left: 6px;
		margin-bottom: 5px;
	}

	max-width: 100%;
	display: flex;
	flex-flow: row wrap;
}
