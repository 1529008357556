@import "@styles/mixins/button";

.tableControls {
	display: flex;
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 13px;

	.title {
		font-weight: 600;
		font-size: 16px;
		line-height: 17px;
	}

	.addEntityButton {
		@include button-basic($padding: 0);
		margin-left: auto;
	}
}

.emptyState {
	padding-top: 16px;
	font-size: 14px;
	line-height: 17px;
	color: #9d9d9d;
}
