@import "@styles/colors";
@import "@styles/mixins/common";

@mixin button-basic($fontSize: 14px, $fontWeight: normal, $padding: 6px 12px) {
	font-size: $fontSize;
	font-weight: $fontWeight;
	padding: $padding;

	border: none;
	background: none;
	box-sizing: border-box;
	text-decoration: none;

	svg {
		display: block;
	}
}

@mixin button-with-icon($marin: 5px) {
	span + svg {
		margin-left: $marin;
	}

	svg + span {
		margin-left: $marin;
	}
}

@mixin button-bright($fontSize: 14px, $fontWeight: normal) {
	@include button-basic(
		$fontSize: $fontSize,
		$fontWeight: $fontWeight,
		$padding: 5px 12px
	);
	@include colored(
		$color: $color-black,
		$background: $color-white
	);
	@include border(
		$borderColor: $color-alto,
		$borderRadius: 4px
	);
}

@mixin button-dark($fontSize: 14px, $fontWeight: normal) {
	@include button-basic(
		$fontSize: $fontSize,
		$fontWeight: $fontWeight,
		$padding: 5px 12px
	);
	@include colored(
		$color: $color-white,
		$background: $color-darken
	);
	@include border(
		$borderColor: $color-darken,
		$borderRadius: 4px
	);
}
