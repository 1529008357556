@import "@styles/mixins/fill-svg";

.artworkImages {
	display: grid;
	grid-template-columns: repeat(5, 160px);
	grid-gap: 10px;
	padding: 10px;
}

.sectionHeader {
	margin-top: 48px;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	color: #282828;
	border-bottom: 1px solid #e9e9e9;
	height: 40px;
}

.tab {
	padding: 40px 0 200px 0;
}

.section {
	width: 352px;

	&.narrow {
		width: 221px;
	}

	&.wide {
		width: 617px;
	}

	&.fullWidth {
		width: 100%;
	}

	.inputWrapper {
		&.narrow {
			width: 98px;
		}

		& + .inputWrapper {
			margin-top: 16px;
		}
	}
}

.section + .section {
	margin-top: 16px;
}

.section + .section.largeMargin {
	margin-top: 40px;
}

.dimensionsWrapper {
	display: flex;
	align-items: flex-end;
	flex: 1;

	.dimensionsInput {
		& + .dimensionsInput {
			margin-left: 5px;
		}

		&.unitToggle {
			flex: 0 0 35px;
		}
	}
}

.magicPasteWrapper {
	margin-bottom: 30px;
}

.collectionsWrapper {
	.title {
		color: #767676;
		font-size: 14px;
		display: block;
		margin-bottom: 5px;
	}

	.collectionsList {
		display: flex;
		flex-wrap: wrap;
		row-gap: 5px;
		column-gap: 5px;
		align-content: flex-start;
		align-items: center;

		.collectionBlock {
			cursor: default;
			padding: 0 2px 0 6px;
			border: 1px solid #dfdfdf;
			box-sizing: border-box;
			border-radius: 4px;
			height: 26px;
			color: #767676;
			font-size: 14px;
		}

		.removeButton {
			@include svg-path-colored(#b8b8b8);

			border: none;
			background: none;

			svg {
				width: 14px;
				height: 14px;
			}

			&:hover {
				@include svg-path-colored(#3e3e3e);
			}
		}

		.addButton {
			@include svg-path-colored(#878787);

			display: flex;
			align-items: center;
			color: #878787;
			border: none;
			background: none;
			font-size: 14px;

			span {
				margin-left: 4px;
			}
		}
	}
}

.dialogWrapper {
	width: 448px;
	background: #ffffff;
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
	border-radius: 4px;

	header {
		font-weight: 600;
		font-size: 16px;
		line-height: 17px;
		padding: 16px;
		border-bottom: 1px solid #eeeeee;
	}

	section {
		padding: 16px;
	}
}
