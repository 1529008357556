/*
	Transitions throughout the app
*/
.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-in {
	-webkit-animation: fade-in 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	animation: fade-in 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.fade-out {
	-webkit-animation: fade-out 1s ease-out both;
	animation: fade-out 1s ease-out both;
}

.slide-in-blurred-right {
	-webkit-animation: slide-in-blurred-right 0.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	animation: slide-in-blurred-right 0.2s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2020-8-6 14:42:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in-bottom {
	0% {
		-webkit-transform: translateY(50px);
		transform: translateY(50px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fade-out {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2020-8-16 10:48:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-left-1
 * ----------------------------------------
 */
/* ----------------------------------------------
 * Generated by Animista on 2020-8-16 10:51:24
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-right
 * ----------------------------------------
 */
@keyframes slide-in-blurred-right {
	0% {
		-webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0) scaleY(1) scaleX(1);
		transform: translateX(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}

@keyframes slide-in-blurred-right {
	0% {
		-webkit-transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		transform: translateX(1000px) scaleX(2.5) scaleY(0.2);
		-webkit-transform-origin: 0% 50%;
		transform-origin: 0% 50%;
		-webkit-filter: blur(40px);
		filter: blur(40px);
		opacity: 0;
	}

	100% {
		-webkit-transform: translateX(0) scaleY(1) scaleX(1);
		transform: translateX(0) scaleY(1) scaleX(1);
		-webkit-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
		-webkit-filter: blur(0);
		filter: blur(0);
		opacity: 1;
	}
}