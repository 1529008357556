.queryInput {
	font-size: 14px;
	height: 44px;
	padding: 0 12px;
	outline: none;

	border: none;
	width: 100%;
}

.suggestionsList {
	max-height: 300px;
}