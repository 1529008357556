@import "./ItemsGridCell/ItemsGridCell.module";

.grid {
	flex: 1;
	overflow-y: hidden;

	.gridRow {
		display: flex;
		overflow: hidden;
		align-items: flex-start;

		.gridFiller {
			@include grid-cell;
		}
	}
}