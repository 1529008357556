$speed: 200ms;

.wrapper {
	overflow: hidden;
	position: relative;

	.menu {
		width: 100%;
	}

	.headMenu {
		position: absolute;

		&.enter {
			position: absolute;
			transform: translateX(-110%);
		}

		&.enterActive {
			transform: translateX(0%);
			transition: all $speed ease;
		}

		&.exit {
			position: absolute;
		}

		&.exitActive {
			transform: translateX(-110%);
			transition: all $speed ease;
		}
	}

	.nestedMenu {
		&.enter {
			transform: translateX(110%);
		}

		&.enterActive {
			transform: translateX(0%);
			transition: all $speed ease;
		}

		&.exit {
			position: absolute;
		}

		&.exitActive {
			transform: translateX(110%);
			transition: all $speed ease;
		}
	}
}