@use "sass:math";
@import "@styles/icons";
@import "@styles/transitions";

// TODO: should be probably moved to params (when universal colors will be presented)
$color-normal-grey: #b4b4b4;
$color-light-blue: #a4aacf;

/**
 * Checkbox with custom styling
 *
 * $size - the actual size of checkbox (implemented via padding property)
 * $tick-size - size of `tick` icon
 * $border-radius - common to use `px` or `%` units
 * $border-size
 * $checked-bg - background of button for checked state
 * $unchecked-border-color - border color for unchecked state
 * $checked-border-color - border color for checked state
 */
@mixin styled-checkbox(
	$size,
	$tick-size,
	$border-radius,
	$border-size,
	$checked-bg,
	$unchecked-border-color,
	$checked-border-color,
	$checkmark-icon: "checkmark-white"
) {
	cursor: pointer;
	box-sizing: border-box;
	border-radius: $border-radius;
	border: $border-size solid $unchecked-border-color;
	-webkit-appearance: none;
	padding: math.div($size, 2);
	transition: background-color $transition-dur-action linear;

	&:checked {
		background-color: $checked-bg;
		background-image: get-icon($checkmark-icon);
		background-size: $tick-size;
		background-repeat: no-repeat;
		background-position: math.div(($size - $tick-size), 2);
		border: $border-size solid $checked-border-color;
	}
}